define("discourse/plugins/onlyoffice-discourse-categories/discourse/templates/connectors/oo-categories/discourse-categories", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
      {{categories}}
  </div>
  */
  {
    "id": "4jjHhL7M",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[41,[28,[32,0],[\"categories\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"categories\"],[[28,[32,1],[\"categories\"],null]]]]],[[[1,[52,[30,1,[\"categories\"]],[28,[30,1,[\"categories\"]],null,null],[28,[32,2],[[30,0],\"categories\",\"[\\\"The `categories` property path was used in the `discourse/plugins/onlyoffice-discourse-categories/discourse/templates/connectors/oo-categories/discourse-categories.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.categories}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"],[13]],[\"maybeHelpers\"],false,[\"if\",\"categories\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/onlyoffice-discourse-categories/discourse/templates/connectors/oo-categories/discourse-categories.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});